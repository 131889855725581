import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { FormattedDate } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { N30 } from '@atlaskit/theme/colors';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DateWrapper = styled.span({
	borderColor: 'transparent',
	backgroundColor: token('color.background.neutral', N30),
	borderRadius: '2px',
	padding: token('space.025', '2px'),
	color: token('color.text', 'black'),
});

export interface DateInterface {
	timestamp: string;
}

/**
 *
 * @param param0
 *
 * There was an issue with the timestamp ADF gives which might be behind in other timezones.
 * For PST, the timestamp is roughly 13 hours behind.
 * Currently, adjusting the timestamp to match the Sydney timezone.
 *
 */
export const DateComponent: FC<DateInterface> = ({ timestamp }) => {
	return (
		<DateWrapper data-testid="data-component">
			<FormattedDate
				value={new Date(parseInt(timestamp)).toLocaleString('en-US', {
					timeZone: 'Australia/Sydney',
				})}
				year="numeric"
				month="short"
				day="numeric"
			/>
		</DateWrapper>
	);
};
